import * as React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Link, useTranslation} from 'gatsby-plugin-react-i18next';

const ArticleCard = ({ data, cardStyle, cardClassName }) => {
  
  const {t} = useTranslation();
  const backgroundImage = getImage(data.frontmatter.image.card);

  return (
    <>
    {cardStyle === "1" &&
      <div key={data.id} className={cardClassName} data-aos="fade-up">
        <Link to={`/article/${data.frontmatter.path}/`} className="card h-100">
          <div className="shape-container">
            <GatsbyImage
              image={getImage(data.frontmatter.image.card)}
              className="card-img-top"
              alt={data.frontmatter.image.alt}
            />
            <div className="shape shape-bottom zi-1" style={{marginBottom:'-.25rem'}}>
              <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1920 100.1">
                <path fill="#fff" d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"></path>
              </svg>
            </div>
          </div>
          <div className="card-body">
            {data.frontmatter.title &&
              <h3 className="card-title text-dark">{data.frontmatter.title}</h3>
            }
            {data.frontmatter.category &&
              <span className="badge bg-primary mb-2">{t('article.categories.' + data.frontmatter.category + '.label')}</span>
            }
            {data.frontmatter.description &&
              <p className="card-text">
                {data.frontmatter.description}
              </p>
            }
          </div>
          <div className="card-footer">
            <div className="d-flex align-items-center">
              <div className="flex-grow-1">
                <div className="d-flex justify-content-start">
                  {data.frontmatter.date_published &&
                    <p className="card-text">
                      <span>{data.frontmatter.date_published}</span>
                      <span className="ms-2">•</span>
                      <span className="ms-2">{t('timeToRead', { time_to_read: data.timeToRead })}</span>
                    </p>
                  }
                </div>
              </div>
            </div>
          </div>
        </Link>
      </div>
    }
    {cardStyle === "2" &&
      <div key={data.id} className={cardClassName} data-aos="fade-up">
        <Link to={`/article/${data.frontmatter.path}/`} style={{backgroundImage: 'url(' + backgroundImage.images.fallback.src + ')', minHeight: '15rem'}} className="card card-stretched-vertical card-transition bg-img-start gradient-y-overlay-sm-gray-900">
          <div className="card-header">
            {data.frontmatter.category &&
              <span className="card-subtitle text-white">{t('article.categories.' + data.frontmatter.category + '.label')}</span>
            }
          </div>
          <div className="card-footer">
            {data.frontmatter.title &&
              <h3 className="card-title text-white">{data.frontmatter.title}</h3>
            }
            <p className="card-link link-light">{t('readMore')} <i className="bi-chevron-right small ms-1"></i></p>
        </div>
        </Link>
      </div>
    }
    {cardStyle === "3" &&
      <div key={data.id} className={cardClassName} data-aos="fade-up">
        <Link to={`/article/${data.frontmatter.path}/`} className="card card-flush h-100">
          <div className="shape-container">
            <GatsbyImage
              image={getImage(data.frontmatter.image.card)}
              className="card-img"
              alt={data.frontmatter.image.alt}
            />
          </div>
          <div className="card-body">
            {data.frontmatter.category &&
              <span className="card-subtitle text-body">{t('article.categories.' + data.frontmatter.category + '.label')}</span>
            }
            {data.frontmatter.title &&
              <h4 className="card-title text-inherit">{data.frontmatter.title}</h4>
            }
            {data.frontmatter.description &&
              <p className="card-text text-body">
                {data.frontmatter.description}
              </p>
            }
          </div>
        </Link>
      </div>
    }
    {cardStyle === "4" &&
      <div key={data.id} className={cardClassName} data-aos="fade-up">
        <Link to={`/article/${data.frontmatter.path}/`} className="card card-flush">
          <div className="row align-items-md-center">
            <div className="col-md-8">
              <GatsbyImage
                image={getImage(data.frontmatter.image.card)}
                className="card-img rounded-2"
                alt={data.frontmatter.image.alt}
              />
            </div>
            <div className="col-md-4">
              <div className="card-body">
                {data.frontmatter.category &&
                  <span className="card-subtitle">{t('article.categories.' + data.frontmatter.category + '.label')}</span>
                }
                {data.frontmatter.title &&
                  <h2 className="card-title">{data.frontmatter.title}</h2>
                }
                {data.frontmatter.description &&
                  <p className="card-text">
                    {data.frontmatter.description}
                  </p>
                }
                <span className="card-link" href="#">{t('readMore')} <i className="bi-chevron-right small ms-1"></i></span>
              </div>
            </div>
          </div>
        </Link>
      </div>
    }
    {cardStyle === "5" &&
      <div key={data.id} className={cardClassName} data-aos="fade-up">
        <Link to={`/article/${data.frontmatter.path}/`} className="card card-sm h-100">
          <div className="p-2">
            <GatsbyImage
              image={getImage(data.frontmatter.image.card)}
              className="card-img"
              alt={data.frontmatter.image.alt}
            />
          </div>
          <div className="card-body">
            {data.frontmatter.title &&
              <h3 className="card-title">{data.frontmatter.title}</h3>
            }
            {data.frontmatter.category &&
              <span className="badge bg-primary mb-2">{t('article.categories.' + data.frontmatter.category + '.label')}</span>
            }
            {data.frontmatter.description &&
              <p className="card-text">
                {data.frontmatter.description}
              </p>
            }
          </div>
          <div className="card-footer border-top">
            <div className="d-flex align-items-center">
              <div className="flex-grow-1">
                <div className="d-flex justify-content-start">
                  {data.frontmatter.date_published &&
                    <p className="card-text">
                      <span>{data.frontmatter.date_published}</span>
                      <span className="ms-2">•</span>
                      <span className="ms-2">{t('timeToRead', { time_to_read: data.timeToRead })}</span>
                    </p>
                  }
                </div>
              </div>
            </div>
          </div>
        </Link>
      </div>
    }
    </>
  )
}

export default ArticleCard