import * as React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { Link, useTranslation } from 'gatsby-plugin-react-i18next';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

// Components
import Layout from '../../components/layout/Layout';
import PaginationBlock from '../../components/block/Pagination';
import ArticleCard from '../../components/card/Article';

// Author
const Author = ({ pageContext, data }) => {

  const {t} = useTranslation();

  const { languagePath } = pageContext;

  const pageImage = { 
    url: data.author.frontmatter.image.profile ? data.author.frontmatter.image.profile.childImageSharp.fluid.srcWebp : null,
    secureUrl: data.author.frontmatter.image.profile ? data.author.frontmatter.image.profile.childImageSharp.fluid.srcWebp : null,
    type: data.author.frontmatter.image.profile ? data.author.frontmatter.image.profile.type : null,
    width: data.author.frontmatter.image.profile ? data.author.frontmatter.image.profile.childImageSharp.gatsbyImageData.width : null,
    height: data.author.frontmatter.image.profile ? data.author.frontmatter.image.profile.childImageSharp.gatsbyImageData.height : null,
    alt: data.author.frontmatter.image.alt ? data.author.frontmatter.image.alt : null
  };

  const pageSchemas = [
    { 
      type: "breadcrumb",
      listItems: [
        { 
          name: t('breadcrumb.authors'),
          item: languagePath + "authors/"
        },
        { 
          name: data.author.frontmatter.name
        },
      ]
    }
  ];

  return (
    <Layout 
      pageType="classic"
      pageTitle={data.author.frontmatter.meta.title} 
      pageDescription={data.author.frontmatter.meta.description} 
      pageKeywords={data.author.frontmatter.meta.keywords} 
      pageImage={pageImage}
      pageSchemas={pageSchemas}
      headerClass="navbar-absolute-top navbar-light navbar-show-hide" 
      headerStyle="light"
    >
      <div className="container content-space-t-3 content-space-t-lg-3 content-space-b-2">
        <nav className="border-bottom" aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item"><Link to={`/`}>{t('breadcrumb.home')}</Link></li>
            <li className="breadcrumb-item"><Link to={`/authors/`}>{t('breadcrumb.authors')}</Link></li>
            <li className="breadcrumb-item active">{data.author.frontmatter.name}</li>
          </ol>
        </nav>
        {data.author &&
          <div className="w-lg-75 border-bottom content-space-1 mx-lg-auto">
            <div className="row">
              <div className="col-sm mb-2 mb-sm-0">
                <div className="d-sm-flex">
                  <div className="flex-shrink-0 mb-3 mb-sm-0">
                    <GatsbyImage
                      image={getImage(data.author.frontmatter.image.profile)}
                      className="avatar avatar-xl avatar-circle border"
                      alt={data.author.frontmatter.image.alt}
                    />                  
                  </div>
                  <div className="flex-grow-1 ms-sm-4">
                    <div className="d-flex justify-content-between align-items-center mb-3">
                      <h1 className="h3 mb-0">
                        {data.author.frontmatter.name &&
                          <Link to={`/author/${data.author.frontmatter.path}/`} className="text-dark">{data.author.frontmatter.name}</Link>
                        }
                      </h1>
                    </div>
                    {data.author.frontmatter.social_networks &&
                      <div className="d-flex justify-content-between align-items-center mb-3">
                        <ul className="list-inline text-center mb-0">
                          {data.author.frontmatter.social_networks.linkedIn && 
                            <li key="linkedIn" className="list-inline-item">
                              <a className="btn btn-outline-primary btn-xs btn-icon rounded" target="_blank" rel="noopener noreferrer" href={data.author.frontmatter.social_networks.linkedIn}>
                                <i className="bi-linkedin"></i>
                              </a>
                            </li>
                          }
                          {data.author.frontmatter.social_networks.facebook && 
                            <li key="facebook" className="list-inline-item">
                              <a className="btn btn-outline-primary btn-xs btn-icon rounded" target="_blank" rel="noopener noreferrer" href={data.author.frontmatter.social_networks.facebook}>
                                <i className="bi-facebook"></i>
                              </a>
                            </li>
                          }
                          {data.author.frontmatter.social_networks.instagram && 
                            <li key="instagram" className="list-inline-item">
                              <a className="btn btn-outline-primary btn-xs btn-icon rounded" target="_blank" rel="noopener noreferrer" href={data.author.frontmatter.social_networks.instagram}>
                                <i className="bi-instagram"></i>
                              </a>
                            </li>
                          }
                          {data.author.frontmatter.social_networks.twitter && 
                            <li key="twitter" className="list-inline-item">
                              <a className="btn btn-outline-primary btn-xs btn-icon rounded" target="_blank" rel="noopener noreferrer" href={data.author.frontmatter.social_networks.twitter}>
                                <i className="bi-twitter"></i>
                              </a>
                            </li>
                          }
                        </ul>
                      </div>
                    }
                    {data.author.body &&
                      <div>
                        <MDXRenderer>
                          {data.author.body}
                        </MDXRenderer>
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
        <div className="w-lg-75 content-space-1 mx-lg-auto">
          <div className="row gx-3 mb-7">
            {data.articles.nodes &&
              data.articles.nodes.map(article => (
                <ArticleCard key={article.id} data={article} cardStyle="1" cardClassName="col-sm-6 col-lg-6 mb-4"/>
              ))
            }
          </div>              
          <div>
            <PaginationBlock 
              path={`author/${data.author.frontmatter.path}`}
              currentPage={pageContext.currentPage} 
              numPages={pageContext.numPages} 
            />
          </div>
        </div>
      </div>
    </Layout>
  )
}

Author.propTypes = {
  pageContext: PropTypes.shape({
    id: PropTypes.string.isRequired,
    language: PropTypes.string.isRequired,
  }),
  data: PropTypes.shape({
    author: PropTypes.shape({
      frontmatter: PropTypes.shape({
        name: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        language: PropTypes.string.isRequired,
      }),
      fields: PropTypes.shape({
        slug: PropTypes.string.isRequired,
      }),
    }).isRequired
  }),
}

export default Author

export const query = graphql`
  query ($author: String!, $language: String!, $skip: Int!, $limit: Int!) {
    site: site {
      siteMetadata {
        socialNetworks {
          linkedIn
          facebook
          messenger
          instagram
          twitter
        }
      }
    }
    author: mdx(
        fileAbsolutePath: { regex: "/data/authors/"}
        frontmatter: { path: { eq: $author }, active: { eq: true }, language: {eq: $language} }
      ) {
      frontmatter {
        meta {
          title
          description
          keywords
        }
        name
        description
        position
        languages
        social_networks {
          linkedIn
          facebook
          instagram
          twitter
        }
        image {
          profile {
            childImageSharp {
              gatsbyImageData(width: 400)
              fluid(quality: 50, maxWidth: 800) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          card {
            childImageSharp {
              gatsbyImageData(width: 800)
            }
          }
          alt
        }
        home
        active
        language
        path
        date(formatString: "D MMMM YYYY")
      }
      body
    }
    articles: allMdx(
      filter: { 
        fileAbsolutePath: { regex: "/data/articles/" },
        frontmatter: { author: { eq: $author }, active: { eq: true }, language: {eq: $language} }
      },
      sort: {fields: frontmatter___date_published, order: DESC}
      limit: $limit
      skip: $skip
    ) {
      nodes {
        frontmatter {
          meta {
            title
            description
            keywords
          }
          title
          category
          tags
          description
          author
          publisher
          image {
            card {
              childImageSharp {
                gatsbyImageData(width: 800)
              }
            }
            alt
          }
          home
          active
          language
          path
          date_published(formatString: "D MMMM YYYY", locale: $language)
          date_modified(formatString: "D MMMM YYYY", locale: $language)
        }
        id
        timeToRead
      }
      totalCount
    }
    locales: allLocale(filter: {ns: {in: ["common", "header", "breadcrumb", "author", "articles", "categories", "tags", "pagination", "footer"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`